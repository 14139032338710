import React, { PureComponent, useState, useCallback, useEffect } from "react";
import Helmet from "react-helmet"
import IndexSPA from "../../components/IndexSPA"

const Index = (props) => {
   return (
      <>
        <Helmet
          title="戴嘉惠 - 社宅公共藝術未來式 / 藝術與空間的碰撞 / HOME TO ALL 我們也可以這樣生活 / 臺北社宅藝術計劃線上展覽"
          meta={[
            {
              name: `description`,
              content: "藝術與建築設計皆為美學重要支柱，思維與訓練卻可能有所異同。公共藝術理想上應該是建築與藝術的交融對話，然而長期彼此難以整合，在實務上總是無法並肩齊驅。為期許社宅公共藝術能跳脫過去的框架，我們希望促進建築與藝術界的彼此傾聽，讓不同的美學思維可能在社宅的公共空間互相交流。",
            },
            {
              property: `og:title`,
              content: "戴嘉惠 - 社宅公共藝術未來式 / 藝術與空間的碰撞 / HOME TO ALL 我們也可以這樣生活 / 臺北社宅藝術計劃線上展覽",
            },
            {
              property: `og:description`,
              content: "藝術與建築設計皆為美學重要支柱，思維與訓練卻可能有所異同。公共藝術理想上應該是建築與藝術的交融對話，然而長期彼此難以整合，在實務上總是無法並肩齊驅。為期許社宅公共藝術能跳脫過去的框架，我們希望促進建築與藝術界的彼此傾聽，讓不同的美學思維可能在社宅的公共空間互相交流。",
            },
            {
              property: `og:image`,
              content: 'https://online.home-to-all.com' + require('../../images/blog/post_3_2.jpg')
            }
          ]}
        >
        </Helmet>
         <IndexSPA />
      </>
   )
 };
 
 
 export default Index;